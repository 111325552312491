<template>
  <div>
    <div class="search">
      <div class="title">排行榜</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="类型:">
          <el-select v-model="params.saleType" placeholder="请选择">
            <el-option
              v-for="item in optionsType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间:">
          <el-select v-model="params.timeType" placeholder="请选择">
            <el-option
              v-for="item in optionsTimes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="手机号:">
          <el-input v-model="params.phone"></el-input>
        </el-form-item>
        <el-form-item label="时间:">
          <el-date-picker
            :editable="false"
            :picker-options="pickerOptions0"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button type="primary" @click="exportTable">导出报表</el-button>
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
   
      style="width: 100%"
    
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <!-- <el-table-column type="index" width="50" align="center">
      </el-table-column> -->
      <el-table-column prop="rank" label="排名" align="center">
      </el-table-column>
      <el-table-column prop="trueName" label="店长姓名" align="center">
      </el-table-column>
      <el-table-column label="店铺头像" width="100" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 70px; height: 70px"
            :src="scope.row.headImg"
            :preview-src-list="[scope.row.headImg]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="userId" label="店长ID"  align="center">
      </el-table-column>
      <el-table-column prop="label" label="标签"  align="center">
      </el-table-column>
      <el-table-column prop="levelTitle" label="当前等级"  align="center">
      </el-table-column>
      <el-table-column prop="shoName" label="店铺名称"  align="center">
      </el-table-column>
      <el-table-column prop="firstOrderCount" label="直销订单数"  align="center">
      </el-table-column>
      <el-table-column prop="firstUserCount" label="一级分销人数" align="center">
      </el-table-column>
      <el-table-column prop="secondUserCount" label="二级分销人数" align="center">
      </el-table-column>
      <el-table-column prop="firsOrderFee" label="直推销售额" align="center">
      </el-table-column>
    
      <!-- <el-table-column
        label="操作"
        align="center"
        >
        <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="record(scope.row)">兑换记录</el-button>
            <el-button type="primary"  size="mini" @click="remove(scope.row)">删除</el-button>
            <el-button type="primary"  size="mini" @click="edit(scope.row)">编辑</el-button>
            <el-button type="primary"  size="mini" @click="code(scope.row)">生成小程序码</el-button>
            <el-button type="primary"  size="mini" @click="appoint(scope.row)">指定发放</el-button>
        </template>
        </el-table-column> -->
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
    
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="分类名称:">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
        <el-form-item label="分类图片:">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { rankingList,exportUserShop } from "../../api/Statistics.js";
export default {
  data() {
    return {
      dialogVisible: false,
      total: 0,
      optionsType:[
        {
          value: 1,
          label: '直销订单数',
        },
        {
          value: 2,
          label: '一级分销人数'
        },
        {
          value: 3,
          label: '二级分销人数'
        },
        {
          value: 4,
          label: '直推销售额'
        },
        {
          value: 5,
          label: '分销销售额'
        },
       
      ],
      optionsTimes:[
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '本周'
        },
        {
          value: 2,
          label: '本月'
        },
        {
          value: 3,
          label: '本季'
        },
        {
          value: 4,
          label: '本年度'
        },
       
      ],
      params: {
        currentPage: 1,
        pageSize: 5,
        saleType: 1,
        timeType: 0,
        // beginTime: "",
        // endTime: "",
      },
      addList: {
        cateId: 0,
        cateName: "",
        cateImg: "",
      },
      addRules: {
        tableData: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async exportTable(){
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let date = +new Date();
        const { data } = await exportUserShop(this.params);
        let url = window.URL.createObjectURL(data); //转换文件流未url
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", `万小店排行榜报表_${date}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        loading.close();
    },
    rowKey(row) {
      return row.userId;
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
    //   this.params.beginTime = this.date ? this.date[0] : "";
    //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await rankingList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>